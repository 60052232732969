import React, { useState } from "react";
const Templates = () => {
    const [data, setData] = useState(
        window.localStorage.getItem("dados-assinatura")?.length > 0
            ? JSON.parse(window.localStorage.getItem("dados-assinatura"))
            : null
    );
    window.localStorage.removeItem("dados-assinatura");
    return (
        <div>
            <table id="x_tableSelected2" style={{ fontSize: 15, backgroundColor: "white", borderCollapse: "collapse" }}>
                <tbody>
                    <tr>
                        <td style={{ width: "114px" }}>
                            <div
                                align="center"
                                style={{
                                    fontSize: "11pt",
                                    fontFamily: "Calibri, arial, sans-serif",
                                    textAlign: "center",
                                    margin: 0,
                                }}
                            >
                                <a
                                    href="https://vendemmia.com.br"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    naturalheight={0}
                                    naturalwidth={0}
                                    width="100%"
                                    height="100%"
                                    align="center"
                                    style={{
                                        cursor: "pointer",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                    alt=""
                                        naturalheight={0}
                                        naturalwidth={0}
                                        width="80"
                                        height="80"
                                        src="https://vendemmia.com.br/assinatura/logo.png"
                                        style={{
                                            maxWidth: "100px",
                                            maxHeight: "100px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </a>
                                <br aria-hidden="true" />
                            </div>
                        </td>
                        <td
                            valign="top"
                            style={{
                                borderStyle: "none none solid none",
                                borderBottomWidth: "1.5pt",
                                borderBottomColor: "#442C76",
                                width: "480px",
                            }}
                        >
                            <table id="x_tableSelected0" style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr>
                                        <td valign="top" style={{ padding: "0 0 4.5pt 0" }}>
                                            <p
                                                style={{
                                                    fontSize: "11pt",
                                                    fontFamily: "Calibri, arial, sans-serif",
                                                    margin: 0,
                                                }}
                                            >
                                                <b>
                                                    <span
                                                        style={{
                                                            color: "rgb(68, 84, 106)",
                                                            fontSize: "14pt",
                                                            fontFamily: "Calibri, arial, sans-serif",
                                                            margin: 0,
                                                        }}
                                                    >
                                                        {data?.name}
                                                    </span>
                                                </b>
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: "11pt",
                                                    fontFamily: "Calibri, arial, sans-serif",
                                                    margin: 0,
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "rgb(68, 44, 118)",
                                                        fontSize: "10pt",
                                                        fontFamily: "Calibri, arial, sans-serif",
                                                        margin: 0,
                                                    }}
                                                >
                                                    {data?.office}
                                                </span>
                                                <span
                                                    style={{
                                                        color: "rgb(237, 90, 36)",
                                                        fontFamily: "Calibri, arial, sans-serif",
                                                        margin: 0,
                                                    }}
                                                />
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top" style={{ padding: "0 0 4.5pt 0" }}>
                                            <p
                                                style={{
                                                    fontSize: "11pt",
                                                    fontFamily: "Calibri, arial, sans-serif",
                                                    margin: 0,
                                                    lineHeight: "13.5pt",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "rgb(68, 44, 118)",
                                                        fontSize: "10pt",
                                                        fontFamily: "Calibri, arial, sans-serif",
                                                        margin: 0,
                                                    }}
                                                >
                                                    email:
                                                    <a
                                                        href="mailto:<?php echo $mail; ?>"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title="<?php echo $mail; ?>"
                                                    >
                                                        {data?.email}
                                                    </a>
                                                    <br aria-hidden="true" />
                                                </span>
                                                <span
                                                    style={{
                                                        color: "rgb(68, 44, 118)",
                                                        fontSize: "10pt",
                                                        fontFamily: "Calibri, arial, sans-serif",
                                                        margin: 0,
                                                    }}
                                                >
                                                    phone: {data?.phone}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top" style={{ padding: "0 0 4.5pt 0" }}>
                                            <p
                                                style={{
                                                    fontSize: "11pt",
                                                    fontFamily: "Calibri, arial, sans-serif",
                                                    margin: 0,
                                                    lineHeight: "13.5pt",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "rgb(68, 44, 118)",
                                                        fontSize: "10pt",
                                                        fontFamily: "Calibri, arial, sans-serif",
                                                        margin: 0,
                                                    }}
                                                >
                                                    {data?.address}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width={140} style={{ width: "105pt" }}>
                            <p
                                align="center"
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                    margin: 0,
                                    padding: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ color: "rgb(255, 255, 255) !important" }}>
                                    <a
                                        href="https://www.facebook.com/vendemmiabr"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "rgb(228, 159, 255) !important" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10.5pt",
                                                fontFamily: "Calibri, arial, sans-serif",
                                                color: "rgb(119, 179, 245) !important",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <img
                                                naturalheight={0}
                                                naturalwidth={0}
                                                src="https://vendemmia.com.br/assinatura/facebook.png"
                                                border={0}
                                                width={16}
                                                height={16}
                                                alt="Vendemmia no Facebook"
                                                style={{
                                                    width: "0.1666in",
                                                    height: "0.1666in",
                                                    minHeight: "auto",
                                                    minWidth: "auto",
                                                }}
                                            />
                                        </span>
                                    </a>
                                </span>
                                <span
                                    style={{
                                        fontSize: "10.5pt",
                                        fontFamily: "Calibri, arial, sans-serif",
                                        color: "rgb(208, 208, 208) !important",
                                    }}
                                >
                                    &nbsp;
                                </span>
                                <span style={{ color: "rgb(255, 255, 255) !important" }}>
                                    <a
                                        href="https://www.youtube.com/c/VendemmiaLog%C3%ADsticaIntegrada"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "rgb(228, 159, 255) !important" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10.5pt",
                                                fontFamily: "Calibri, arial, sans-serif",
                                                color: "rgb(119, 179, 245) !important",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <img
                                                naturalheight={0}
                                                naturalwidth={0}
                                                src="https://vendemmia.com.br/assinatura/youtube.png"
                                                border={0}
                                                width={16}
                                                height={16}
                                                id="x_Imagem_x0020_3"
                                                alt="Vendemmia no YouTube"
                                                style={{
                                                    width: "0.1666in",
                                                    height: "0.1666in",
                                                    minHeight: "auto",
                                                    minWidth: "auto",
                                                }}
                                            />
                                        </span>
                                    </a>
                                </span>
                                <span
                                    style={{
                                        fontSize: "10.5pt",
                                        fontFamily: "Calibri, arial, sans-serif",
                                        color: "rgb(208, 208, 208) !important",
                                    }}
                                >
                                    &nbsp;
                                </span>
                                <span style={{ color: "rgb(255, 255, 255) !important" }} data-ogsc="black">
                                    <a
                                        href="https://www.linkedin.com/company/vendemmiabr"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "rgb(228, 159, 255) !important" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10.5pt",
                                                fontFamily: "Calibri, arial, sans-serif",
                                                color: "rgb(119, 179, 245) !important",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <img
                                                naturalheight={0}
                                                naturalwidth={0}
                                                src="https://vendemmia.com.br/assinatura/linkedin.png"
                                                border={0}
                                                width={16}
                                                height={16}
                                                alt="Vendemmia no linkedin"
                                                style={{
                                                    width: "0.1666in",
                                                    height: "0.1666in",
                                                    minHeight: "auto",
                                                    minWidth: "auto",
                                                }}
                                            />
                                        </span>
                                    </a>
                                </span>
                                <span
                                    style={{
                                        fontSize: "10.5pt",
                                        fontFamily: "Calibri, arial, sans-serif",
                                        color: "rgb(208, 208, 208) !important",
                                    }}
                                >
                                    &nbsp;
                                </span>
                                <span style={{ color: "rgb(255, 255, 255) !important" }} data-ogsc="black">
                                    <a
                                        href="https://www.instagram.com/vendemmiabr"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "rgb(228, 159, 255) !important" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10.5pt",
                                                fontFamily: "Calibri, arial, sans-serif",
                                                color: "rgb(119, 179, 245) !important",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <img
                                                naturalwidth={0}
                                                src="https://vendemmia.com.br/assinatura/instagram.png"
                                                border={0}
                                                width={16}
                                                height={16}
                                                alt="Vendemmia no instagram"
                                                style={{
                                                    width: "0.1666in",
                                                    height: "0.1666in",
                                                    minHeight: "auto",
                                                    minWidth: "auto",
                                                }}
                                            />
                                        </span>
                                    </a>
                                </span>
                            </p>
                        </td>
                        <td valign="top" style={{}}>
                            <table id="x_tableSelected0" style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr>
                                        <td valign="top" style={{ padding: "0 0 4.5pt 0" }}>
                                            <p
                                                style={{
                                                    fontSize: "11pt",
                                                    fontFamily: "Calibri, arial, sans-serif",
                                                    margin: 0,
                                                    lineHeight: "13.5pt",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "rgb(68, 44, 118)",
                                                        fontSize: "10pt",
                                                        fontFamily: "Calibri, arial, sans-serif",
                                                        margin: 0,
                                                    }}
                                                >
                                                    <a
                                                        href="https://vendemmia.com.br"
                                                        target="_blank"
                                                        rel="noopener
                                              noreferrer"
                                                        data-auth="NotApplicable"
                                                        title="https://vendemmia.com.br"
                                                        style={{
                                                            fontSize: "14.67px",
                                                            fontFamily: "Calibri, arial, sans-serif",
                                                            backgroundColor: "white",
                                                            margin: 0,
                                                        }}
                                                        data-linkindex={1}
                                                    >
                                                        <span
                                                            style={{
                                                                color: "rgb(68, 44, 118)",
                                                                fontSize: "10pt",
                                                                fontFamily: "Calibri, arial, sans-serif",
                                                                margin: 0,
                                                            }}
                                                        >
                                                            vendemmia.com.br
                                                        </span>
                                                    </a>
                                                    <br aria-hidden="true" />
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} width="100%" style={{ width: "100%" }}>
                            <img src="https://vendemmia.com.br/assinatura/banner.gif" alt=""/>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>
                <span
                    style={{
                        fontSize: "11px",
                        fontFamily: "Calibri, arial, sans-serif",
                        textAlign: "center",
                        marginTop: "15px",
                    }}
                >
                    IMPORTANTE: Esta mensagem, incluindo qualquer anexo, é destinada exclusivamente para a(s) pessoa(s) a
                    quem é dirigida, podendo conter informação confidencial e/ou legalmente protegida. Se você não for o
                    destinatário desta mensagem, por favor, não divulgue, copie, distribua, examine ou, de qualquer forma,
                    utilize a informação aqui contida, por ser ilegal. Caso você tenha recebido esta mensagem por engano,
                    pedimos que nos retorne este e-mail e elimine seu conteúdo em sua base de dados, registros ou sistema de
                    controle.
                </span>
            </p>
            <p>
                <span
                    style={{
                        fontSize: "11px",
                        fontFamily: "Calibri, arial, sans-serif",
                        textAlign: "center",
                        marginTop: "15px",
                    }}
                >
                    IMPORTANT: This message, including any attachment, is intended exclusively for the person(s) to whom it
                    is addressed, and may contain confidential and / or legally protected information. If you are not the
                    recipient of this message, please do not disclose, copy, distribute, examine or, in any way, use the
                    information contained herein, as it is illegal. If you have received this message in error, we ask that
                    you return this email to us and delete your content in your database, records or control system.
                </span>
            </p>
        </div>
    );
};
export default Templates;
